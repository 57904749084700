#root {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #000000;
    font-size: 13px;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
table{
    line-height: normal;
    font-weight: normal;
    font-size: medium;
    font-style: normal;
}
.form-control{
  border-radius: 0!important;
}
.form-control:focus {
    color: #474a4c!important;
    border-color: #7fb5d4!important;
    outline: 0!important;
    -webkit-border-radius: 0!important;
    -webkit-box-shadow: none!important;
    box-shadow: none!important
}
.form-control[readonly] {
  background-color: unset;
}
.page-header h1>.btn,
.page-header h1>.btn-group,
.page-header h2>.btn,
.page-header h2>.btn-group {
    margin-left: 5px;
    margin-top: -2px
}
.page-header {
    padding: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    border: 0
}
.page-header h1,
.page-header h2 {
    line-height: 31px;
    margin-top: 0;
    margin-bottom: 0
}
.page-header h1 {
    font-size: 18px;
    font-weight: 400
}
.table>tbody>.highlight:hover, .table>tbody>tr:hover, .table_row:hover, ul.table>li:hover {
    background-color: #f2f2f2!important;
    -webkit-transition: background-color .2s linear;
    -moz-transition: background-color .2s linear;
    -ms-transition: background-color .2s linear;
    -o-transition: background-color .2s linear;
    transition: background-color .2s linear;
}
.table>thead>tr>th{
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 4px;
    padding-right: 4px;
    border: 1px solid #dee2e6;
}
.table>tbody>tr>td{
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #dfe1e5;
    padding-left: 4px;
    padding-right: 4px;
    border-color: #dfe1e5;
    vertical-align: middle;
}
.active_rows>tbody>tr, .active_rows>tbody>tr:hover, .sortable_row {
    cursor: pointer;
}
.table>tbody>tr:hover>td, .table_row:hover, ul.table>li:hover {
    border-color: #adbcc3;
    -webkit-transition: border-color .2s linear;
    -moz-transition: border-color .2s linear;
    -ms-transition: border-color .2s linear;
    -o-transition: border-color .2s linear;
    transition: border-color .2s linear;
}
.dropdown .btn-sm{
    line-height:0.3;
}
.dropdown .btn-sm{
    padding: 0.25rem 0.3rem;
    background-color: #fff;
}
.dropdown .btn,.dropdown .btn:hover{
    border-color: #cecece;
    background-color: #fff;
}
.dropdown .btn:focus,.dropdown .btn:active{
    color: #5d97b9;
    background-color: #fff;
    border-color: #adadad;
}
.dropdown.show .btn.dropdown-toggle {
    background-color: #fff!important;
    border-color: #adadad!important;
}
.navbar.bg-light{
    background-color:#fff!important;
}
.page-item.active .page-link {
    color: #8e9599!important;
    background-color: #dfe1e5!important;
    border-color: #dfe1e5!important;
}
.page-item .page-link{
    color: #8e9599!important;
}
.page-item:not(.active):hover .page-link{
    cursor: pointer;
}
.pagination{
    display: inline-flex;
}
.pagination>li:first-child>a, .pagination>li:first-child>span {
    margin-left: 0;
}
.pagination li>a, .pagination li>span {
    margin-left: 4px;
    -webkit-transition: color .25s linear, border-color .25s linear;
    -moz-transition: color .25s linear, border-color .25s linear;
    -ms-transition: color .25s linear, border-color .25s linear;
    -o-transition: color .25s linear, border-color .25s linear;
    transition: color .25s linear, border-color .25s linear;
}
.react-confirm-alert-overlay{
  background: rgba(105,105,105, 0.9)!important;
}
.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #fff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #696969;
}

.custom-ui > h1 {
  margin-top: 0;
}

.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #696969;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #696969;
  font-size: 14px;
}
.ft-16{
  font-size:16px;
}
.ft-11{
  font-size:11px;
}
.ft-14{
  font-size:14px;
}
.ft-20{
  font-size:20px;
}
.ft-25{
  font-size:25px;
}
.border-error,.selectize.border-error div:first-child{
  border-color:#dc3545
}
.ft-30{
  font-size:30px;
}
.ft-60{
  font-size:60px;
}
.pointer{
  cursor: pointer;
}
.alert-fixed,.alert-fixed-modal {
  z-index: 50;
  margin: 0 15px;
}
.alert-fixed{
  right: 0;
}
.react-confirm-alert-overlay{
  z-index: 9999!important;
}
button:disabled{
  cursor: not-allowed;
}
.width_100{
  width: 100%;
}
.tab-content{
  width: 100%;
}
.MuiSwitch-colorPrimary.Mui-checked {
    color: #1976d2!important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #1976d2!important;
}
.dropZoneListThumb{
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
}
.thumbInner{
  display: flex;
  min-width: 0px;
  overflow: hidden;
  margin:0 auto!important;
}
.thumbInner img{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.dropZoneListThumb .delete_picture{
  bottom: -20px;
}
.message_upper_box{
    border: 2px solid #E0E0E0;
    border-radius: 5px;
}
.setup_step:first-child:before {
    width: 50%;
    left: auto;
}
.setup_step:last-child:before {
    width: 50%;
}
.setup_step:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 15px;
    right: 0;
    z-index: 0;
    background-color: #eef4f8;
    height: 2px;
    width: auto;
}
.setup_card {
    font-size: 14px;
    position: relative;
    z-index: 1;
    height: 42px;
    line-height: 30px;
    padding: 4px;
    background-color: #eef4f8;
    border: 2px solid #eef4f8;
    color: #1d70b7;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}
.active .setup_card {
    background-color: #1d70b7;
    border-color: #1d70b7;
    color: #fff;
}
.card_wrapper i.fa-check-circle{
    position: absolute;
    right: 3px;
    font-size: 25px;
    z-index: 5;
    top: -10px;
    border-radius: 50%;
    background-color: #fff;
}
.card_wrapper .card-header{
  height: 75px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card_wrapper .card-body .card-text.description{
  white-space: pre-wrap;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.card_wrapper .card-img-top{
  height: 120px;
  object-fit: cover;
}
.training_line_separator{
  border-bottom: 1px solid #C7C7CC;
}
table.no-background tr:hover{
  background-color:transparent!important;
}
table.no-background tr:hover td{
  border-color:#dfe1e5!important;
}
.table>thead>tr>th{
  vertical-align: middle;
}
.search_remove {
    position: absolute;
    right: 25px;
    top: 10px;
    font-size: 20px;
    color: #CED4DA;
    cursor: pointer;
}

/* new styling*/
@import url('https://fonts.googleapis.com/css?family=Poppins');
#root {
    font-family: 'Poppins', sans-serif !important;
}
html {
    height: 100%;
    overflow-y: scroll;
    position: relative;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
body {
    background-color: #F6FAFD;
}
.content {
    width: 100%;
    float: left;
    padding: 0px 30px;
}
.content h4 {
    width: 100%;
    float: left;
    /*margin-bottom: 40px;*/
}
.btn-general {
    background: #33459E;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    border: 1px solid #33459E;
    box-shadow: none!important;
    line-height: 20px;
    font-size: 14px;
}
.btn-general:hover {
    background-color: #33459E;
    border-color: #33459E;
    opacity: .8;
}
.btn-general:not(:disabled):not(.disabled):active {
    background-color: #703BE7;
    border-color: #703BE7;
}
.nav-general {
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: none!important;
    display: initial;
    background: #fff;
    border: 1px solid #33459E;
    color: #33459E!important;
}
.nav-general.active {
    background: #33459E!important;
    color: #fff!important;
}
.nav-general:focus {
    outline: none;
}
.table>tbody>tr>td .bg-warning td,
.table>tbody>tr>td.bg-warning {
    background-color: #E8AB22!important;
    color: #fff;
}
.form-group label {
    font-size: 15px;
   
    color: #2c3e50 ;
}
.form-group .form-control {
    font-size: 14px;
}
.table>tbody>tr>td {
    color: #515152;
    background-color: #fff;
    font-size: 14px;
    line-height: 24px;
    border: 0;
    padding-left: 15px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}
.table>tbody>tr>td:after {
    content: '';
    height: 60%;
    width: 1px;
    position: absolute;
    right: 0;
    background-color: #dfe1e5;
    bottom: 20%;
}
.table>tbody>tr>td:last-child:after {
    display: none;
}
.table>tbody>tr:hover>td:after {
    background-color: #fff;
}
.table>tbody>tr>td.bg-success ,
.table>tbody>tr.bg-success>td,
.table>tbody>tr>td.bg-danger {
    background-color: #6FBEA2!important;
    color: #fff;
}
.table>tbody>tr>td.bg-info{
    background-color: #4656A7!important;
    color: #fff;
}
.table>tbody tr td .dropdown-item:focus, .table>tbody tr td .dropdown-item:hover {
    background-color: #fff;
}
button.btn.btn-primary {
    background-color: #703BE7;
    border-color: #703BE7;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}
.table>thead>tr>th {
    background-color: #F6FAFD;
    border: 0!important;
    padding-left: 15px;
}
.table>thead>tr>th span,
.table>thead>tr>th i {
    color: #000;
}
.content.menu,
.content.menu nav#header_nav {
   /* background-image: linear-gradient(to right, #4A5AA9, #703BE7);*/
   background-image: linear-gradient(to right, #703BE7, #703BE7)
   

    
}
.content.menu nav#header_nav a {
    color: #fff;
    border-radius: 0;
}
.content.menu a.nav-link {
    background-color: transparent!important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px!important;
    margin: 0px 60px;
    position: relative;
}
.content.menu .dropdown a.nav-link {
    text-transform: none;
    margin: 0;
}
.content.menu a.nav-link.active::before {
    content: "";
    border-bottom: 1px solid white;
    width: 25%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
}
span.avatar {
    width: 35px;
    height: 35px;
    float: left;
}
span.avatar img {
    width: 100%;
    float: left;
    border-radius: 50%;
}
.menu a.dropdown-toggle.nav-link {
    padding-left: 50px;
}
.logo img {
    width: 45%;
    float: left;
    padding-right: 10px;

}
table.text-left.active_rows.table.table-sm {
    border-collapse: separate;
    border-spacing: 0 10px;
}
.table>tbody>tr>td:last-child {
    border-right: 0;
}
.table>tbody>tr:hover>td, .table_row:hover, ul.table>li:hover {
    border-color: #dfe1e5;
}
a.dropdown-item {
    width: auto;
    display: inline-block;
    color: #BCBDBF;
    clear: none;
    padding: 10px;
    text-align: center;
}
a.dropdown-item i {
    font-size: 20px;
}
.dropdown-item.active, .dropdown-item:active {
    background: #dee4ff!important;
    color: #16181b;
}
.dropdown-item:focus, .dropdown-item:hover {
    background-color: #dee4ff;
}
.content.menu nav#header_nav a.dropdown-item {
    color: #515152;
    font-weight: 600;
    padding: 10px 30px;
}
.content.menu nav#header_nav a.dropdown-item.logout {
    background: #4454a6;
    color: #fff;
    margin: 0 auto;
    float: none;
    width: 70%;
    display: block;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
}
nav#pills-tab {
    display: block;
}
.content .nav-pills .nav-link.btn-general {
    margin-left: 10px;
    display: initial;
}
.content .nav-pills .nav-link.active {
    background: #fff;
    border: 1px solid #33459E;
    color: #33459E;
}
.back-button a {
    color: #BCBDBF;
    transition:  .3s all ease;
}
.back-button i {
    border: 1px solid #BCBDBF;
    border-radius: 50%;
    line-height: normal;
    padding: 10px 13px;
}
.back-button span {
    margin-left: 10px;
}
h3 .btn-link i {
    color: #BCBDBF;
    font-size: 20px;
}
.back-button a:hover {
    text-decoration: none;
    color: #2c3e50;
    transition:  .3s all ease;
}
.back-button a:hover i {
    border: 1px solid #2c3e50;
}
.content .nav-pills .nav-link.btn-general:hover {
    color: #fff;
}
.content .nav-pills .nav-link.active:hover {
    color: #33459E;
}
.alert-info {
    background: #525152;
    color: #fff;
    font-weight: 600;
    border-color: #525152;
}
.alert-info .text-info {
    color: #fff!important;
}
.form-group i.fa-times-circle {
    color: #BCBDBF;
    font-size: 18px;
}
.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #525152!important;
    opacity: 1;
}
.MuiSwitch-colorPrimary.Mui-checked {
    color: #ffffff!important;
    padding: 13px;
}
.MuiSwitch-switchBase {
    padding: 13px;
}
.dropZoneListThumb {
    width: auto;
    height: auto;
}
.delete_picture {
    width: auto;
    float: right;
}
.delete_picture i {
    line-height: 24px;
}
.form-control {
    /*border: 0;*/
}
.thumbInner.pointer {
    width: 100%;
}
.dropZoneListThumb {
    border: 0;
    width: 100%;
}
.table .thead-dark th {
    background: #555;
}
#header-navbar-nav .nav-link.active {
    border: none;
}
.table>tbody tr td {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.table>tbody tr td:last-child {
    border-right: 1px solid #FFF;
    border-left: none;
}
.table>tbody tr td:first-child {
    border-left: 1px solid #fff;
    border-right: none;
}
.table>tbody tr:hover td {
    background: #fff;
    border-color: #dfe1e5;
    border-top: 1px solid #703BE7;
    border-bottom: 1px solid #703BE7;
    border-left: none;
    border-right: none;
    color: #515152!important;
}
.table>tbody tr:hover td:first-child {
    border-left: 1px solid #703BE7;
}
.table>tbody tr:hover td:last-child {
    border-right: 1px solid #703BE7;
    border-left: none;
}
.table>tbody tr:hover td::after {
    background-color: #dfe1e5;
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: #703BE7;
    background-color: #703BE7;
}
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
    border-color: #adb5bd!important;
}
.table .text-center.checkbox {
    padding-left: 4px;
}
.checkbox .custom-control-label::after, .checkbox .custom-control-label::before {
    left: -20px;
}
tbody .checkbox .custom-control-label::after, tbody .checkbox .custom-control-label::before {
    top: 10px;
}
button.dropdown-toggle.btn-sm {
    padding: 6px 4px;
}
button.dropdown-toggle.btn-sm:focus {
    box-shadow: none;
    color: #703BE7;
}
button.dropdown-toggle.btn-sm:not(:disabled):not(.disabled):active {
    box-shadow: none;
    background-color: #703BE7;
    border-color: #703BE7;
}
.setup_card {
    background: #fff;
    border: 3px solid #D6D6D6;
    color: #703BE7;
    width: 52px;
    height: 52px;
    border-radius: 50%;
}
.active .setup_card {
    background-color: #703BE7;
    border-color: #D6D6D6;
}
.setup_card .badge {
    background: #fff;
    width: 100%;
    height: 100%;
    color: #703BE7;
    line-height: normal;
    padding: 3px;
    font-size: 18px;
    border: 2px solid #D6D6D6;
}
.active .setup_card .badge {
    background: #703BE7;
    color: #fff;
}
.setup_step:before {
    height: 5px;
    top: 25px;
    background-color: #d6d6d6;
}
.setup_step:first-child:before {
    width: 58%;
}
.content .search-bar h4 {
    width: auto;
    float: left;
}
.content .search-bar input.form-control {
    float: left;
    width: auto;
}
span.search-icon {
    float: left;
    font-size: 22px;
    color: #9C9CA5;
}
.card {
    padding: 15px;
    border: 1px solid #fff;
    border-radius: 0;
}
.border-success {
    box-shadow: 0px 0px 5px 5px #dee2e6;
}
.table>tbody .border-success td {
    border-bottom: 1px solid #28a745;
    border-top: 1px solid #28a745;
}
.table>tbody .border-success td:first-child {
    border-left: 1px solid #28a745;
}
.table>tbody .border-success td:last-child {
    border-right: 1px solid #28a745;
}
.card:hover {
    border: 1px solid #703BE7;
}
.card-body {
    padding: 10px 0px 0px 0px;
}
.card-text span:first-child {
    color: #703BE7;
    margin-bottom: 8px;
    font-size: 14px;
}
.card-footer {
    background: #fff;
    color: #6c757d;
    padding-bottom: 0;
    margin-top: 5px;
    padding-top: 5px;
}
.card-footer .btn {
    color: #2c3e50;
    text-decoration: underline;
}
.card-footer .btn:hover {
    text-decoration: none;
}
.card-text.border-right {
    border-right: 1px solid #2c3e50!important;
}
.setup_step a {
    color: #2c3e50;
    text-decoration: none;
}
.date-box {
    background: #fff;
}
.left-side, .right-side {
    width: 50%;
    float: left;
    padding: 50px;
    position: relative;
}
.left-side:after {
    content: '';
    height: 70%;
    width: 1px;
    position: absolute;
    right: 0;
    background-color: #dfe1e5;
    bottom: 15%;
}
.selectize input {
    border: 1px solid #9c9ca5;
    border-radius: 4px!important;
}
.selectize div.css-1pahdxg-control,.selectize div.css-yk16xz-control{
    border-color: hsl(0,0%,80%)!important;
    box-shadow: unset!important;
}
.selectize div.css-1pahdxg-control:hover{
    border-color: hsl(0,0%,80%)!important;
}
/*calendar*/
.left-side ul {
    list-style: none;
}
.month {
    padding: 10px 25px;
    width: 100%;
    background: #ffffff;
    text-align: center;
}
.month ul {
  margin: 0;
  padding: 0;
}
.month ul li {
  color: #2c3e50;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 3px;

}
.month .prev {
  float: left;
font-size: 30px;
}
.month .next {
  float: right;
    font-size: 30px;
}
.weekdays {
  margin: 0;
  padding: 10px 0;
  background-color: #fff;
}
.weekdays li {
  display: inline-block;
  width: 13.6%;
  color: #703BE7;
  text-align: center;
  font-size: 18px;
}
.days {
  padding: 10px 0;
  background: #fff;
  margin: 0;
}
.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size:18px;
  color: #777;
}
.days li .active {
  padding: 5px;
  background: #703BE7;
  color: white !important;
}
span.big-font {
    width: 100%;
    float: left;
    font-size: 24px;
}
span.big-font-clock {
    font-size: 70px;
    margin-top: 50px;
    width: 100%;
    float: left;
}
/*end-calendar*/
.message_upper_box {
    border-radius: 0px;
    border: none;
    padding-right: 0;
}
.input-group input.form-control {
    border: 1px solid #ced4da;
}
.white-bg {
    background: #fff;
    padding: 40px 30px;
}
.white-bg .badge {
    padding: 8px 10px;
}
.page-login {
    background: url(./assets/images/login-bg-new1.png);
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    min-height: 100%;
}
.page-login-vertical {
    position: relative;
    transform: none;
    top: 25%;
    left: 4%;
}
.page-login-box {
  font-family: Raleway, sans-serif;
  max-width: 576px;
  padding: 8px 37px;
  border-radius: 10px;
    margin: 0;
    /*background: #fff;*/
}
.page-login .btn-white {
    color: #703BE7!important;
    background: #fff;
    font-weight: 600;
    border: 1px solid;
    font-size: 15px!important;
    padding: 0px;
    height: 61px;
    border-radius: 0.2rem!important;
    font-size: 18px!important;
    width: 100%;
}
.page-login .round {
    color: #fff!important;
    background: #703BE7;
   font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
       border-radius: 0.9rem;
        padding: .375rem .75rem;
        width:auto;
   
}


.page-login .btn-default img {
    vertical-align: middle;
    margin: 0;
    float: left;
    padding: 12px 6px 7px 12px;
}
.page-login .btn-default span.button-text {
    display: inline-block;
    float: left;

    margin-top: 14px;
    font-size: 16px;
      padding: 1px 5px 0px 7px;
  
}
.page-login .btn-default:hover span.button-text {
   
}
.page-login-box h1 {
    color: #703BE7;
    text-transform: uppercase;
}
.page-login-panel .btn.btn-link {
    color: #703BE7;
    padding-left: 0px;
    font-weight: normal;
}
.page-login-panel .btn.btn-link span.big-text {
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 800;
}
.page-login .btn-white:hover {
    background-color: #fff!important;
    border-color: #703BE7;
    transition: all 0.5s ease;
}
.page-login .btn-white:focus {
    outline: none;
    box-shadow: none;
}
.content .btn.btn-info {
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: none!important;
    font-size: 14px;
}
.dropdown-trainings.dropdown .btn.dropdown-toggle {
    color: #2c3e50;
    font-size: 14px;
    width: 100%;
    text-align: left;
    box-shadow: none!important;
}
.dropdown-trainings.dropdown a.dropdown-item {
    width: 100%;
    float: left;
    color: #2c3e50!important;
    padding-bottom: 0px;
    text-align: left;
    font-size: 14px;
}
.dropdown-trainings.dropdown .dropdown-toggle::after {
    float: right;
    margin-left: 0px;
    vertical-align: middle;
    margin-top: 10px;
}
.dropdown-trainings .dropdown-menu.show {
    margin: 0;
    min-width: 100%;
}
.input-group .form-control {
    height: 40px;
}
.input-group .input-group-append .btn {
    border-color: #ced4da;
    background: #e9ecef;
}
.input-group .input-group-append .btn:hover {
    color: #6c757d;
}
label.MuiFormControlLabel-root {
    width: 100%;
    float: left;
}
.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #703BE7;
  text-align: center;
  height: 1.5em;
}
.hr-text:before {
    content: '';
    background: #703BE7;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}
.hr-text:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 4px 8px;
     color: #703BE7;
     font-size: 14px;
     font-weight: bold;
    background-color: #ebe9f3;
}
@media (min-width:320px) and (max-width: 480px) {
    .mt-3-mobile {
        margin-top: 1rem!important;
    }
    .content {
        padding: 0px 15px;
    }
    .logo {
        width: 70%;
        float: left;
    }
    .content .mt-3.text-left {
        text-align: center!important;
    }
    .table>tbody>tr>td {
        font-size: 12px;
        padding: 2px 4px;
        font-weight: 700;
    }
    .table>thead>tr>th {
        font-size: 12px;
        text-align: left;
        padding: 10px 4px;
    }
    .navbar-light .navbar-toggler-icon {
        width: 100%;
    }
    button.navbar-toggler.collapsed {
        width: 15%;
        float: left;
        border: none;
        padding: 0;
    }
    nav#header_nav {
        justify-content: normal;
        height: 80px;
    }
    .text-right.navbar-nav.hidden {
        display: block;
        width: 15%;
        float: left;
    }
    .navbar-collapse .dropdown.nav-item {
        display: none;
    }
    .navbar-collapse {
        position: absolute;
        top: 79px;
        z-index: 2;
        background-image: linear-gradient(to right, #4A5AA9, #703BE7);
        left: -15px;
        padding: 0px;
        max-width: 65%;
    }
    .content.menu .navbar-collapse a {
        margin: 0;
        text-align: left;
        padding: 30px 15px;
    }
    .content.menu a.nav-link.active::before {
        display: none;
    }
    .content.menu nav#header_nav a.nav-link.active {
        background-color: #6270B4!important;
    }
    span.navbar-toggler-icon {
        background-image: url(./assets/images/hamburger-icon.png)!important;
        width: 30px!important;
        height: 30px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .content .nav-pills .nav-link.btn-general {
        float: left;
        margin: 10px 0px 0px 10px;
        padding: 10px;
        font-size: 10px;
    }
    .alert.alert-info.show {
        float: left;
        margin-bottom: 5px!important;
        font-size: 12px;
        padding: 10px;
    }
    .tab-content label.text-right {
        text-align: left!important;
    }
    a.dropdown-item i {
        font-size: 16px;
    }
    .card-text span {
        font-size: 12px!important;
        width: auto;
        float: left;
        margin-right: 5px;
        margin-bottom: 0px!important;
    }
    .card_wrapper .card-body .card-text.description {
        height: 60px;
        min-height: 60px;
        font-size: 14px;
    }
    .card-body .text-justify {
        font-size: 16px;
        text-align: left!important;
        margin-top: 0px!important;
    }
    .back-button, .setup_step {
        margin-bottom: 20px;
    }
    .steps {
        display: block!important;
    }
    .setup_step {
        margin-bottom: 20px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .card-text .badge.badge-light {
        width: 20px;
        height: 20px;
        padding: 5px;
    }
    .left-side, .right-side {
        width: 100%;
    }
    .left-side:after {
        display: none;
    }
    .form-group .btn-info {
        float: left;
    }
    .card-text.border-right {
        border-right: none!important;
    }
    nav#pills-tab {
        display: flex;
    }
    nav#pills-tab .nav-link {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .content h4 {
        margin-bottom: 20px;
    }
    .white-bg {
        padding: 20px 15px;
    }
    .page-login-box h1 {
        font-size: 24px;
    }
    .page-login-vertical {
        left: unset!important;
        /* top: 30%!important; */
        right: unset!important;
        padding: 14px;
    }
    .page-login .btn-default span.button-text,
    .page-login .btn-default img {
        padding: 9px 8px 9px 8px;
    }
    .team-table>tbody>tr>td {
        padding: 8px 4px;
    }
}
/* @media (min-width: 481px) and (max-width: 575px) {
    .page-login-vertical {
        left: unset!important;
        right: unset!important;
    }
    .page-login-vertical {
        top: 35%!important;
    }
} */
@media (min-width:481px) and (max-width: 767px) {
    /* .page-login-vertical {
        top: 40%!important;
        right: unset!important;
        left: unset!important;
    } */
    nav#pills-tab {
        display: flex;
    }
    nav#pills-tab .nav-link {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .content {
        padding: 0px 15px;
    }
    .logo {
        width: auto;
        float: left;
        flex: none;
        max-width: 70%;
    }
    .content .mt-3.text-left {
        text-align: center!important;
    }
    .table>tbody>tr>td {
        font-size: 12px;
        padding: 10px 4px;
    }
    .table>thead>tr>th {
        font-size: 12px;
        text-align: left;
        padding: 10px 4px;
    }
    .navbar-light .navbar-toggler-icon {
        width: 100%;
    }
    span.navbar-toggler-icon {
        background-image: url(./assets/images/hamburger-icon.png)!important;
        width: 30px!important;
        height: 30px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    button.navbar-toggler.collapsed {
        width: 15%;
        float: left;
        border: none;
        padding: 0;
    }
    nav#header_nav {
        justify-content: normal;
        height: 80px;
    }
    .text-right.navbar-nav.hidden {
        display: block;
        width: 15%;
        float: left;
    }
    .navbar-collapse .dropdown.nav-item {
        display: none;
    }
    .navbar-collapse {
        position: absolute;
        top: 79px;
        z-index: 2;
        background-image: linear-gradient(to right, #4A5AA9, #703BE7);
        left: -15px;
        padding: 0px;
        max-width: 50%;
    }
    .content.menu .navbar-collapse a {
        margin: 0;
        text-align: left;
        padding: 30px 15px;
    }
    .content.menu a.nav-link.active::before {
        display: none;
    }
    .content.menu nav#header_nav a.nav-link.active {
        background-color: #6270B4!important;
    }
    .logo img {
        width: 50%;
        float: none;
    }
    .content .nav-pills .nav-link.btn-general {
        float: left;
        margin: 10px 0px 0px 10px;
        padding: 10px;
    }
    .alert.alert-info.show {
        float: left;
        margin-bottom: 5px!important;
        font-size: 12px;
        padding: 10px;
    }
    .tab-content label.text-right {
        text-align: left!important;
    }
    a.dropdown-item i {
        font-size: 16px;
    }
    .card-text span {
        font-size: 12px!important;
        width: auto;
        float: left;
        margin-right: 5px;
        margin-bottom: 0px!important;
    }
    .card_wrapper .card-body .card-text.description {
        height: 70px;
        min-height: 70px;
        font-size: 14px;
    }
    .card-body .text-justify {
        font-size: 16px;
        text-align: left!important;
        margin-top: 0px!important;
    }
    .steps {
        display: block!important;
    }
    .setup_step {
        margin-bottom: 20px;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .card-text .badge.badge-light {
        width: 20px;
        height: 20px;
        padding: 5px;
    }
    .left-side, .right-side {
        width: 100%;
    }
    .left-side:after {
        display: none;
    }
    .message_upper_box {
        margin-top: 30px!important;
        padding-left: 0px;
    }
    .form-group .btn-info {
        float: left;
    }
    .card-text.border-right {
        border-right: none!important;
    }
    .content h4 {
        margin-bottom: 20px;
    }
}
@media (min-width:768px) and (max-width: 1024px) {
    /* .page-login-vertical {
        left: unset;
        top: 35%;
    } */
    nav#pills-tab {
        display: flex;
    }
    nav#pills-tab .nav-link {
        margin-bottom: 10px;
        margin-right: 10px;
    }
    .content.menu a.nav-link {
        font-size: 12px!important;
        margin: 0px;
        text-align: center;
    }
    .dropdown.nav-item {
        margin-left: 15px;
    }
    /*.content.menu nav#header_nav .dropdown a span {
        display: none;
    }*/
    .content, .logo img {
        padding: 0;
    }
    .logo img {
        width: 100%;
    }
    .content .nav-pills .nav-link.btn-general {
        float: left;
        margin-left: 5px;
        margin-bottom: 10px;
    }
    .table>thead>tr>th, .table>tbody>tr>td {
        font-size: 12px;
        font-weight: 700;
    }
    .card-text span {
        font-size: 12px!important;
        width: auto;
        float: left;
        margin-right: 5px;
        margin-bottom: 0px!important;
    }
    .card_wrapper .card-body .card-text.description {
        height: 100px;
        min-height: 100px;
        font-size: 14px;
    }
    .card-body .text-justify {
        font-size: 16px;
        text-align: left!important;
        margin-top: 0px!important;
    }
    .card-text .badge.badge-light {
        width: 20px;
        height: 20px;
        padding: 5px;
    }
    .form-label span , .custom-control-label {
        font-size: 12px;
    }
    .form-group .btn-info {
        font-size: 14px;
        float: left;
    }
    .input-group label {
        font-size: 14px;
        margin-top: 10px;
    }
    .form-group.md-100 {
        width: 100%;
        max-width: 100%;
        float: left;
        flex: 100%;
    }
    .card-text.border-right {
        border-right: none!important;
    }
    .card p.col-sm-12.col-md-12.col-lg-4.d-inline-block {
        width: 100%;
        max-width: 100%;
        flex: 100%;
    }
    .card-body .text-justify:first-child {
        min-height: 50px;
    }
}

@media (min-width:1025px) and (max-width: 1200px) {
    nav#pills-tab .nav-link {
        margin-right: 5px;
    }
    .content.menu a.nav-link {
        font-size: 14px!important;
        margin: 0px;
        text-align: center;
    }
    .content {
        padding: 0px 15px;
    }
    .logo img {
        width: 100%;
    }
    /*.content.menu nav#header_nav .dropdown a span {
        display: none;
    }*/
    .dropdown.nav-item {
        margin-left: 15px;
    }
    .form-label span, .custom-control-label {
        font-size: 12px;
    }
    .form-group .btn-info {
        font-size: 14px;
    }
    .input-group label {
        font-size: 14px;
        margin-top: 10px;
    }
    .card-body .text-justify:first-child {
        min-height: 50px;
    }
    .card_wrapper .card-body .card-text.description {
        min-height: 80px;
    }
}
@media (min-width: 1201px) and (max-width: 1500px) {
    nav#pills-tab .nav-link {
        margin-right: 5px;
    }
    .content.menu a.nav-link {
        font-size: 14px!important;
        margin: 0px;
    }
}
@media (min-width: 1501px) {
    nav#pills-tab .nav-link {
        margin-right: 5px;
    }
}
/*end new styling*/

.training_status .badge.badge-success{
    background-color: #6FBEA2!important;
}
.training_status .badge.badge-info{
    background-color: #4656A7!important;
}
.training_status .badge.badge-warning{
    background-color: #E8AB22!important;
}
.training_view h3 .btn-link i,.training_view h3 .btn-link span {
    color: #4656a7!important;
    font-size: inherit!important;
}
.btn-yellow{
    background-color: #E8AB22!important;
    border-color: #E8AB22!important;
}
.btn-yellow:focus{
    box-shadow: none!important;
}
.avatar_container{
    height:5px;
    width:40px!important;
}
.avatar_image{
    max-width:100%!important;
    object-fit:cover;
    border-radius: 50%;
}
.page-login-vertical-registration{
    top: 20%;
    position: relative;
    transform: none;
    display: block;
    right: 15px;
}
.react-datepicker__input-container{
    border: 1px solid #ccc;
    background: #FFFF;
    border-radius: 4px;
}
.react-datepicker__input-container input{
    cursor: pointer;
}
.btn_preview_browser{
    color: #703BE7;
    text-decoration: none;
}
.btn_preview_browser span,.btn_preview_browser i{
    color: #703BE7;
}
.carousel-caption h3{
    color: black;
}
.onboarding_carousel .carousel-caption{
    position: unset!important;
}
.onboarding_carousel .carousel-control-next i,.onboarding_carousel .carousel-control-prev i{
    color: black;
    font-size: 2rem;
}
.min-vh-50{
    min-height: 50vh!important;
}
.carousel-img{
    border: 1px solid #C4C4C4;
}