.page-login {
	    -webkit-background-size: cover ;
	    -moz-background-size: cover;
	    -o-background-size: cover ;
	    background-size: cover ;
	    position: absolute ;
	    left: 0 ;
	    top: 0 ;
	    right: 0 ;
	    bottom: 0 ;
		 	overflow:hidden;
			overflow-y: auto;
	}
	.page-login-vertical {
	    display: block;
	     right: 15px;
	}
	.page-login-box-login {
	    font-family: Raleway, sans-serif;
	    max-width: 576px;
	    padding: 8px 37px;
	    border-radius: 10px;
	}
	.page-login-box-login h1 {
	    margin-top: 0;
	    /*margin-bottom: 22px*/
	}
	.page-login-box-login h1 img {
	    width: 188px;
	    height: auto
	}
	.page-login-panel {
		width:100%;
	    color: #b7b7b7;
	    padding: 25px 50px 20px;
	    margin-bottom: 8px
	}
	.page-login-panel input:-webkit-autofill,
	.page-login-panel input:-webkit-autofill:focus,
	.page-login-panel input:-webkit-autofill:hover {
	    -webkit-text-fill-color: #474a4c;
	    -webkit-box-shadow: 0 0 0 1000px #fff inset
	}
	

	.page-login .text-gray {
	    color: #979797;
	}
	.page-login-panel a {
	    color: #2377b8
	}
	.page-login-panel a:hover {
	    color: #4991c9;
	    text-decoration: none!important;
	}
	.page-login-banner a,
	.page-login-panel .form-group span.forgot_text {
	    color: #f39200
	}

	.page-login-panel,
	.page-login-panel .btn,
	.page-login-panel .form-control {
	    font-size: 16px
	}
	.page-login-panel .form-group {
	    margin-bottom: 10px
	}
	.page-login-panel .form-control {
	    padding-left: 48px;
	    height: 48px;
	}
	.page-login-panel .alert-danger{
		padding-right: 5px;
	    background-color: #fff;
	    border: 1px solid #bdbdbd;
	    padding-left: 5px;
	    border-top: 10px solid #f39200;
	    margin-bottom: -15px;
	    margin-top: 25px;
	    margin-right: 15px;
	    margin-left: 15px;
	    border-radius: 0px;
	    color: #f39200;
	    font-size: 17px;
	}
	.page-login-panel .alert-danger .close{
		color: #f39200;
	}
	.page-login-icon {
	    position: absolute;
	    left: 27px;
	    top: 25px;
	    margin-top: -15px
	}
	span.page-login-icon i {
	    margin-top: 1px;
	    font-size: 23px;
	    margin-left: 3px
	}
	.page-login-panel .page-login-actions {
	    padding-top: 12px;
	    min-height: 68px;
	    margin-bottom: 4px
	}
	page-login-icon i {
	    margin-top: 1px;
	    font-size: 23px;
	    margin-left: 3px
	}
	.page-login-panel .page-login-actions {
	    padding-top: 12px;
	    min-height: 68px;
	    margin-bottom: 4px
	}
	.page-login .btn-default {
	    border-radius: 0;
	}
	.page-login .btn-white:hover {
	    background-color: #fff!important;
    	border-color: #343d98;
    	transition: all 0.5s ease;
	}
	.page-login .btn-default img {
	    display: inline-block;
	}
	.page-login .alert-danger:after{
	    display: none
	}
	.page-login-actions .checkbox {
	    padding-top: 0;
	    margin-top: -1px;
	    height: 34px
	}
	.page-login-actions input[type=checkbox] {
	    width: 14px;
	    height: 14px;
	    padding: 0;
	    margin-top: 4px;
	    margin-top: 6px\9
	}
	.register-logo{
	  background: url(../.././assets/images/header_logo_new_small.png);
	  background-repeat: no-repeat;
	  background-size: cover;
	  position: relative;
	  width: 170px;
		height: 170px;
		margin-bottom: 1rem;
		margin-left:75px;
	}
