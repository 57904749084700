.registration-input-group-prepend-i{
	display: flex;
	align-items: center;
	padding: .375rem .75rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #e9ecef;
	border: 1px solid #ced4da;
}
.registration-input-group-input{
	border-left:none;
}