#header_nav{
	padding-left:0rem;
	padding-right:0rem;
}
#header-navbar-nav .nav-link{
	font-size: 1.2rem;
}
#header-navbar-nav .nav-link:not(.dropdown-toggle){
	color:white;
	border-radius: 0px;
}
#header-navbar-nav .nav-link:not(.active):not(.dropdown-toggle){
	background-color: #666666;
}
#header-navbar-nav .nav-link:not(:first-child):not(.dropdown-toggle){
	margin-left:5px;
}
#header-navbar-nav .nav-link.active{
	background-color: #007bff;
	color:#fff
}
#header-navbar-logo #logo{
	/*padding-left: 0px;*/
}
#header-navbar-logo h5{
	color: #fff;
}
.simple_channel .dropdown-toggle.nav-link::after{
	display: none;
}
.simple_channel #logo{
	pointer-events: none;
}
@media screen and (max-width: 768px) {
  	#header-navbar-nav .nav-link:not(.dropdown-toggle){
		color: rgba(0,0,0,.5);
	}
	#header-navbar-nav .nav-link:not(.active):not(.dropdown-toggle){
		background-color: unset;
	}
	#header-navbar-nav .nav-link:not(:first-child):not(.dropdown-toggle){
		margin-left:0px;
	}
	#header-navbar-nav .nav-link.active{
		background-color: unset;
		color:black;
	}
}